a, i.action {
  text-decoration: none;
  color: var(--mainPrimaryColor);
  font-weight: bold;
}

a.dark-link {
  text-decoration: none;
  color: var(--primaryDarkColor);
  font-weight: bold;
}

a.dark-link:hover {
  color: var(--primaryDarkColor);
  font-weight: bold;
}

a:hover, i.action:hover {
  color: var(--mainSecondaryColor);
}

.acts-as-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: var(--mainPrimaryColor);
}

.link-effect {
  text-decoration: none;
  color: #000000;
}

.link-effect:hover {
  color: #000000;
}

.card.active {
  border: 2px solid var(--mainSecondaryColor);
}