.stacked-search-jobs-form {
  width: 100%;
}

.stacked-search-jobs-form input.form-control {
  border-radius: 2rem;
  padding-left: 1.5rem;
}

.stacked-search-jobs-form label {
  padding-left: 1.5rem;
}