.home-background-left-magnet {
  background-image: url("/src/images/abstract-magnet.png"), url("/src/images/home-background.png"), linear-gradient(#FFFFFFCC, #FFFFFFCC);
  background-blend-mode: normal, overlay;
  background-repeat: no-repeat, no-repeat;
  background-position-x: 65%, 100%;
  background-position-y: 0%, bottom;
  background-attachment: fixed;
  background-size: 38.5rem, cover, cover;
  color: var(--secondaryDarkColor);
}

.home-background-no-magnet {
  background-image: url("/src/images/home-background.png");
  background-position: center;
  background-color: #FFFFFFCC;
  background-size: cover;
  background-blend-mode: overlay;
  color: var(--secondaryDarkColor);
}